import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/setting-up-umbraco-in-visual-studio",
  "date": "2014-04-28",
  "title": "SETTING UP UMBRACO IN VISUAL STUDIO",
  "author": "admin",
  "tags": ["development", "asp.net", "umbraco"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article we discuss about setting an Umbraco project with ASP.NET with step by step instructions"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Setting up Visual Studio 2013 project`}</h2>
    <p>{`Open Visual Studio 2013 and start up a new `}<strong parentName="p">{`ASP.NET Web Application`}</strong>{` project give it a name and save it to your preferred location and click `}<strong parentName="p">{`OK`}</strong>{` as shown in Figure 1 below. This is the new project type introduced in Visual Studio 2013, which is a starting point for all web applications.`}</p>
    <p>{`Create new ASP.NET Web Application Project Select the `}<strong parentName="p">{`Empty`}</strong>{` template from the **New ASP.NET Project dialog and click OK to create the project as shown in figure 2 below. This will give us a clean slate to start with our Umbraco 7 project, Umbraco 7 project is now auto-configured to work with MVC so we can start with a clean slate and let the Umbraco NuGet package configure everything for us. `}</p>
    <p>{`Select Empty template`}</p>
    <h2>{`Installing the Nuget Package Start the Package Manager Console if it's not running already and fire the command `}<strong parentName="h2">{`PM`}</strong>{` > `}<strong parentName="h2">{`Install-Package UmbracoCms`}</strong>{` as shown in figure 3 below. The Umbraco team has published the Umbraco CMS in Nuget so it becomes the easiest way to get the latest codebase and get started with Umbraco. The above command will take a while to execute while every dependent package is found and installed for your project so wait patiently while the process completes. Figure 3: Run command on Package Manager to download and install Umbraco 7 in our project. Once all the files required are downloaded and installed, the Package Manager will ask you a question to request permission to override the web.config file as shown below in figure 4. Umbraco 7 setup has a lot of configurations defined in its web.config file so its necessary that we allow it to use its web.config file so type **Y\` at the command prompt and press enter to allow the process to continue. This process will continue until all the required files are downloaded and configured. Once the installation process completes you will be shown a readme file with information on getting started.`}</h2>
    <p><img parentName="p" {...{
        "src": "http://img.readitlater.com/i/www.systenics.com/media/15755/Windows-Live-Writer_53e6beb1251c_C2A0_img4/RS/w640.png",
        "alt": "Installing the Nuget Package Start"
      }}></img></p>
    <p>{`Figure 4: Allow Nuget to override the web.config file With these easy steps, we are ready to get started with the Umbraco instance installation. The manual steps that were required in Umbraco 6 to set the project in MVC mode are no longer required and everything is automatically configured for you in Umbraco 7. You can build the project and start with the Umbraco 7 configuration process. Note: In our testing, we found that if we install a start kit during configuration, the installation fails with a `}<strong parentName="p">{`definition not found for class HttpResponseMessage exception. On further investigation, we found that the start kit creates a `}</strong>{`UmbContactController.cs`}<inlineCode parentName="p">{` class file under **App_Code** folder to handle the Contact Form. This class references the **HttpResponseMessage** class, which fails because even through the**System.Net.Http`}</inlineCode>{` assembly containing this class is referenced in Visual Studio, code within `}<strong parentName="p">{`App_Code\` is compiled during execution by the asp.net runtime which looks into the web.config file to file the reference to System.Net.Http assembly. If it does not find it there it fails and throws an exception, failing the start kit installation. So if you wish to install a start kit during configuration then open the projects web.config file in Visual Studio and add the following `}</strong>{`assemblies`}<strong parentName="p">{` section under the `}</strong>{`compilation** section at line number 90 as shown in listing 1 below.`}</p>
    <p>{`\`\`csharp`}</p>
    <compilation defaultLanguage="c#" debug="false" batch="false" targetFramework="4.5">      
 <assemblies>
        <add assembly="System.Net.Http, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b03f5f7f11d50a3a" />
  </assemblies>
 .....
    </compilation>
``` 
    <p>{`Listing 1: Add assemblies section to web.config file You can build the project and hit `}<strong parentName="p">{`F5`}</strong>{` to run it. Your browser should load up the Umbraco Installation screen as shown in figure 5 below.
Follow the on-screen instructions get your application configured. `}</p>
    <p><img parentName="p" {...{
        "src": "http://img.readitlater.com/i/www.systenics.com/media/15765/Windows-Live-Writer_53e6beb1251c_C2A0_img5/RS/w640.png",
        "alt": "Umbraco 7 Installation"
      }}></img></p>
    <p>{`Figure 5: Umbraco 7 Installation`}</p>
    <h2>{`Installation and Configuration of Umbraco 7`}</h2>
    <p>{`The steps shown below are completely optional but still, it's good to know for those who are getting started with Umbraco development.
In your browser click on the `}<strong parentName="p">{`Let’s get started!`}</strong>{` button as shown in figure 5 above.
On the next license, screen click on `}<strong parentName="p">{`Accept and Continue`}</strong>{` to move forward. Now you are shown the `}<strong parentName="p">{`Database configuration`}</strong>{` screen.
You can select the database of your choice, I like to use SQL CE 4 during testing and development since it is lightweight and easier to move around since all you need to do is copy the folder and all settings get transferred over. `}</p>
    <p>{`Figure 6 shows the SQL CE 4 selected as the database. Click `}<strong parentName="p">{`Install`}</strong>{` to install them appropriately selected database and move to the next step. `}</p>
    <p><img parentName="p" {...{
        "src": "http://img.readitlater.com/i/www.systenics.com/media/15775/Windows-Live-Writer_53e6beb1251c_C2A0_img6/RS/w640.png",
        "alt": "SQL CE 4"
      }}></img></p>
    <p>{`Figure 6: Select SQL CE 4 database After database installation is done you will see a confirmation message as shown in figure 7 below, click Continue to move ahead. `}</p>
    <p><img parentName="p" {...{
        "src": "http://img.readitlater.com/i/www.systenics.com/media/15785/Windows-Live-Writer_53e6beb1251c_C2A0_img7/RS/w640.png",
        "alt": "Figure 7"
      }}></img></p>
    <p>{`Figure 7: Continue after database installation On the Create User screen provides the details to create the admin user.
This user will have access to the Admin control panel of Umbraco and all details of the user can be changed at a later stage from within Umbraco.
I create the user using temporary credentials as shown below in figure 8, please make sure that you use your strong credentials. `}</p>
    <p>{`Click `}<strong parentName="p">{`Create User`}</strong>{` to create the user and move ahead. `}</p>
    <p>{`Name: `}<strong parentName="p">{`Admin`}</strong>{`
Email: `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:admin@systenics.com"
        }}>{`admin@systenics.com`}</a>{` `}</strong>{`
Username: `}<strong parentName="p">{`root`}</strong>{`
Password: `}<strong parentName="p">{`@123test`}</strong></p>
    <p><img parentName="p" {...{
        "src": "http://img.readitlater.com/i/www.systenics.com/media/15795/Windows-Live-Writer_53e6beb1251c_C2A0_img8/RS/w640.png",
        "alt": "Figure 8"
      }}></img></p>
    <p>{`Figure 8: Create User Once the user is created you are given the option to select a start kit. Umbraco 7 has updated the starter kits to provide two basic ones, advanced features like blog, news is not present in the kits today.
If you want to create your site from scratch then its best to not use any of these starter kits.
Select `}<strong parentName="p">{`No Thanks I do not wish to install a start kit\` image as shown in figure 9 below to setup Umbraco without using an existing starter kit.
A confirmation prompt will open up asking you to confirm your selection, click `}</strong>{`OK** to proceed. `}</p>
    <p><img parentName="p" {...{
        "src": "http://img.readitlater.com/i/www.systenics.com/media/15805/Windows-Live-Writer_53e6beb1251c_C2A0_img9/RS/w640.png",
        "alt": "Figure 9"
      }}></img></p>
    <p>{`Figure 9: Select Starter Kits The above steps will finish the installation and configuration of Umbraco 7 and you will be presented with a confirmation screen as seen in figure 10 below.
This completes the configuration of a blank Umbraco 7 installation. But remember since we did not choose to use a Start Kit our site will be blank and it needs content defined before you can publish or deploy the site. `}</p>
    <p>{`It's out of the scope for this blog post to define how to configure your site, please consult the `}<a parentName="p" {...{
        "href": "http://our.umbraco.org/documentation"
      }}>{`Umbraco Documentation`}</a>{` for further advice. `}</p>
    <p><img parentName="p" {...{
        "src": "http://img.readitlater.com/i/www.systenics.com/media/15815/Windows-Live-Writer_53e6beb1251c_C2A0_img10/RS/w640.png",
        "alt": "Figure 10"
      }}></img></p>
    <p>{`Figure 10: Umbraco 7 Installation Complete In this short blog, I have tried to cover the essential steps required to configure Umbraco 7 with Visual Studio 2013. I plan to build on this configuration in my future blog posts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      